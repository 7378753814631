<template>
  <div>
    <Bins
      :bins="bins"
      :loading="!!loading"
      @edit="onEdit"
      @delete="onDelete"
      @add-bins="onAddBins"
    />
    <router-view></router-view>
  </div>
</template>

<script>
import Bins from "./components/Bins.vue";

import {
  BINS_QUERY,
  BIN_STATUS,
  BIN_BATCH_COMPLETED_SUB,
  BIN_DELETE,
} from "./graphql";

export default {
  name: "BinsView",
  components: {
    Bins,
  },
  props: {
    storeId: {
      type: [Number, String],
      required: true,
    },
    raiLinkTo: {
      type: Object,
      required: true,
    },
  },
  data: (vm) => ({
    bins: [],
    loading: 0,
  }),
  apollo: {
    binsStatus: {
      // TODo - hook loading here also
      query: BIN_STATUS,
      variables() {
        return {
          storeId: this.storeId,
        };
      },
      update(data) {
        return data.binStatus;
      },
    },
    bins: {
      loadingKey: "loading",
      query: BINS_QUERY,
      variables() {
        return { storeId: this.storeId };
      },
      update(data) {
        return data.bins;
      },
    },
    $subscribe: {
      binBatchCompleted: {
        query: BIN_BATCH_COMPLETED_SUB,
        result() {
          this.$apollo.queries.binsStatus.refetch();
          this.$apollo.queries.bins.refetch();
        },
      },
    },
  },
  methods: {
    onBack() {
      this.$router.back();
    },
    onAddBins() {
      this.$router.push({
        name: this.raiLinkTo.addBinsInList,
        query: {
          startNumber: this.binsStatus.nextBin,
        },
        preserveQuery: true,
      });
    },
    onEdit(bin) {
      this.$router.push({
        name: this.raiLinkTo.editBin,
        params: { binId: bin.id },
        preserveQuery: true,
      });
    },
    async onDelete(bin) {
      const result = await this.$apollo.mutate({
        mutation: BIN_DELETE,
        variables: { id: bin.id },
      });
      // TODO - what with this ?
      // why for deletion we need to remove something and for update we do not ?
      this.mutationUpdate(this.$apollo.getClient().cache, result);
    },
    mutationUpdate(cache, result) {
      // Remove the bin from the cache
      const bin = result?.data?.mutationResult?.bin;
      const variables = { storeId: this.storeId };
      const { bins } =
        cache.readQuery({
          query: BINS_QUERY,
          variables,
        }) || [];

      const index = bins.findIndex((x) => x.id === bin.id);

      // Return early if the bin isn't found in the query
      if (index === -1) return;

      // Remove the bin from the cache read result
      bins.splice(index, 1);

      // Write the bins back to the cache, excluding
      // the archived one
      cache.writeQuery({
        query: BINS_QUERY,
        variables,
        data: { bins },
      });
    },
  },
};
</script>
