<template>
  <div>
    <VxConfirmDialog
      v-model="showDeleteFilledDialog"
      :title="$t('backstock.setup.bins.deleteBinFilledInfoDialog.title')"
      hide-cancel
    >
      <p>
        {{ $t("backstock.setup.bins.deleteBinFilledInfoDialog.message[0]") }}
      </p>
      <p>
        {{ $t("backstock.setup.bins.deleteBinFilledInfoDialog.message[1]") }}
      </p>
    </VxConfirmDialog>
    <VxConfirmDialog
      v-model="showDeleteBinConfirmDialog"
      :title="$t('backstock.setup.bins.deleteBinConfirmDialog.title')"
      @ok="$emit('delete', binToDelete)"
    >
      <p>
        {{
          $t("backstock.setup.bins.deleteBinConfirmDialog.message", {
            binName: binToDelete.name,
          })
        }}
      </p>
      <VxAlert
        type="warning"
        v-html="$t('backstock.setup.bins.deleteBinConfirmDialog.alert')"
      />
    </VxConfirmDialog>
    <VxDataList
      class="backstock-bins"
      :loading="loading"
      :headers="headers"
      :pagination-options="paginationOptions"
      :sort-options="sortOptions"
      @update:page="onPageUpdate"
      @update:itemsPerPage="onItemsPerPageUpdate"
      @update:sort="onSortUpdate"
      @showMore="onShowMore"
    >
      <template #body>
        <BinListItem
          v-for="item in binsPaginated"
          :key="item.id"
          :item="item"
          @edit="onEdit(item)"
          @delete="onDelete(item)"
        />
      </template>
    </VxDataList>
    <div class="d-flex justify-end mt-4">
      <VxBtn @click="onAddBins">
        {{ $t("backstock.setup.bins.addButtonText") }}
      </VxBtn>
    </div>
  </div>
</template>

<script>
import BinListItem from "./BinListItem.vue";
import {
  VxConfirmDialog,
  LocalPaginationUtils,
  PaginationOptionsUtils,
  VxDataList,
  VxAlert,
  VxBtn,
} from "@/core-ui";

export default {
  name: "Bins",
  components: {
    VxDataList,
    BinListItem,
    VxConfirmDialog,
    VxAlert,
    VxBtn,
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    bins: {
      type: Array,
      default: () => [],
    },
  },
  data: () => {
    const defaultPaginationOptions =
      PaginationOptionsUtils.buildDefaultPaginationOptions({
        itemsPerPage: 5,
        pageSizes: [5, 25, 50, 100],
      });

    const defaultSortOptions = PaginationOptionsUtils.buildDefaultSortOptions({
      sortBy: "name",
    });

    return {
      paginationOptions: defaultPaginationOptions,
      sortOptions: defaultSortOptions,
      binToDelete: { name: "" },
      showDeleteFilledDialog: false,
      showDeleteBinConfirmDialog: false,
    };
  },
  computed: {
    binsPaginated() {
      // NOT GOOD to do mutation of pagination options here, but the easiest way to simulate server side like behaviour
      LocalPaginationUtils.updateOptionsFromLocalData(
        this.paginationOptions,
        this.bins
      );
      return LocalPaginationUtils.paginateLocalItems(
        this.bins,
        this.paginationOptions,
        this.sortOptions,
        {
          ignoreCase: true,
        }
      );
    },
    headers() {
      return [
        {
          propertyPath: "name",
          label: this.$t("backstock.setup.bins.dataList.nameColumn"),
          sortable: true,
        },
        {
          propertyPath: "isFilled",
          label: this.$t("backstock.setup.bins.dataList.isFilledColumn"),
          sortable: true,
        },
        {
          propertyPath: "actions",
          label: this.$t("backstock.setup.bins.dataList.actionsColumn"),
          cssClass: "actions",
        },
      ];
    },
  },
  methods: {
    onShowMore() {
      LocalPaginationUtils.fetchMoreData(this.paginationOptions);
    },
    onPageUpdate(page) {
      LocalPaginationUtils.updatePage(this.paginationOptions, page);
    },
    onItemsPerPageUpdate(itemsPerPage) {
      LocalPaginationUtils.updateItemsPerPage(
        this.paginationOptions,
        itemsPerPage
      );
    },
    onSortUpdate(header) {
      LocalPaginationUtils.updateSort(
        this.sortOptions,
        this.paginationOptions,
        header.propertyPath
      );
    },

    onEdit(item) {
      this.$emit("edit", item);
    },
    onAddBins() {
      this.$emit("add-bins");
    },
    onDelete(bin) {
      if (bin.isFilled) {
        this.showDeleteFilledDialog = true;
        return;
      }

      // TODO - binToDelete -> this can be done nicer, but we should change confirm dialog to open but with message override also.
      this.binToDelete = bin;
      this.showDeleteBinConfirmDialog = true;
    },
  },
};
</script>

<style lang="scss">
.backstock-bins thead th.actions {
  width: 120px;
}
</style>
