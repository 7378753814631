<template>
  <VxDataListItem v-bind="$attrs" v-on="$listeners">
    <template #desktop>
      <td>
        {{ item.name }}
        <span v-if="item.default" class="grey--text font-italic ml-1">
          Default
        </span>
      </td>
      <td :class="{ 'font-italic': item.terminal }">
        <v-icon :color="item.isFilled ? 'success' : ''">
          {{ item.isFilled ? "$checkCircle" : "$cancel" }}
        </v-icon>
      </td>
      <td>
        <!-- TODO: remove color="" after fixing VxBtn -->
        <VxBtn color="" icon @click.stop="$emit('edit', item)">
          <v-icon>$edit</v-icon>
        </VxBtn>
        <VxBtn color="" icon @click.stop="$emit('delete', item)">
          <v-icon>$delete</v-icon>
        </VxBtn>
      </td>
    </template>
    <template #mobile>
      <v-list-item-content>
        <v-list-item-title>
          {{ item.name }}
        </v-list-item-title>
      </v-list-item-content>
      <v-list-item-content>
        <v-list-item-title>
          <v-icon :color="item.isFilled ? 'success' : ''">
            {{ item.isFilled ? "$checkCircle" : "$cancel" }}
          </v-icon>
        </v-list-item-title>
      </v-list-item-content>
      <v-list-item-action>
        <VxBtn color="" icon @click.stop="$emit('edit', item)">
          <v-icon>$edit</v-icon>
        </VxBtn>
      </v-list-item-action>
      <v-list-item-action>
        <VxBtn color="" icon @click.stop="$emit('delete', item)">
          <v-icon>$delete</v-icon>
        </VxBtn>
      </v-list-item-action>
    </template>
  </VxDataListItem>
</template>

<script>
import { VxDataListItem, VxBtn } from "@/core-ui";

export default {
  name: "BinListItem",
  components: { VxDataListItem, VxBtn },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
};
</script>
